export const useApp = defineStore('app', () => {
  /**
   * State
   */
  const autoSync: Ref<boolean> = ref(
    localStorage.getItem('autoSync') === null
      ? true
      : localStorage.getItem('autoSync') === 'true',
  );
  const quizAsForm: Ref<boolean> = ref(
    localStorage.getItem('quizAsForm') === null
      ? false
      : localStorage.getItem('quizAsForm') === 'true',
  );

  /**
   * Event listeners.
   */
  watch(autoSync, (value: boolean) => {
    localStorage.setItem('autoSync', value === true ? 'true' : 'false');
    log(
      'settings.auto-sync',
      'Auto-sync is now ' + (value ? 'on' : 'off') + '.',
    );
  });
  watch(quizAsForm, (value: boolean) =>
    localStorage.setItem('quizAsForm', value === true ? 'true' : 'false'),
  );

  return {
    // State
    autoSync,
    quizAsForm,
  };
});
